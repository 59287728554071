.block.filter {
    .amshopby-filter-current {
        .amshopby-item {
            background: $secondary;
        }
    }

    .amshopby-slider-container {
        .am-slider {
            .ui-slider-handle {
                transform: none;
                background: $secondary;
            }
        }

        .ui-slider-range {
            background: $secondary;
        }
    }
}
