header {
    .header {
        background-color: $primary;
        color: $white;
    }

    .top-wrapper {
        margin-bottom: 0;
    }

    .logo {
        img {
            display: block;
            height: 3rem;
        }
    }
}

.back-to-top {
    border-radius: 0;
}

.enter-splash {
    .enter-splash-body {
        border: 2px solid $secondary;
    }

    .enter-splash-buttons {
        a {
            background-color: $secondary;
            border-color: $secondary;

            &:last-of-type {
                color: $secondary;
                background-color: $white;
                border-color: $secondary;

                &:hover {
                    background-color: $secondary;
                }
            }
        }
    }

    &.hide {
        display: none;
        opacity: 0;
        pointer-events: none;
    }
}

.header-minicart,
.navbar-minicart,
.header-wishlist, 
.navbar-wishlist {
    .counter {
        background: $white;
        color: $secondary;
    }
}