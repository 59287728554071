.navbar {
    &.bg-light {
        background-color: $secondary !important;
    }

    .nav-item {
        &:hover {
            .dropdown-menu {
                right: initial;
            }

            a {
                &.nav-link {
                    color: rgba($black, 0.7);
                }
            }
        }

        a.nav-link {
            color: $white;

            @include media-breakpoint-down($main-breakpoint) {
                color: $primary;
            }
        }

        + .nav-item {
            @include media-breakpoint-up(lg) {
                border-left: 1px solid $white;
            }
        }

        &:hover {
            background-color: $white;
        }
    }
}

.navbar.bg-light {
    @include media-breakpoint-down($main-breakpoint) {
        background-color: $primary !important;
    }
}

$iconClasses: (
    toggler: $icon-bars,
    search: $icon-search,
    account: $icon-user,
    minicart: $icon-shopping-basket,
    wishlist: $icon-heart,
);
$iconDesktopSize: 1.4rem;
$iconMobileSize: 1.25rem;

@each $class, $icon in $iconClasses {
    .header,
    .navbar {
        &-#{$class} {
            .icon {
                @include svg-icon($iconDesktopSize, $iconDesktopSize, $icon, $white);
                vertical-align: middle;

                @include media-breakpoint-down($main-breakpoint) {
                    @include svg-icon($iconMobileSize, $iconMobileSize, $icon, $white);
                }
            }
        }
    }
}
