.catalog-product-view {
    .slick-arrow {
        font-size: $photo-slider-arrow-size;
    }

    .product-studio {
        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }



    .product-details-wrapper {
        padding-top: 0;
    }
}

.product-main-info {
    .price-wrapper {
        color: $primary;
        font-size: $h2-font-size;
        font-weight: 700;
    }

}

.product-price-flex {
    padding-top: 1rem;
    padding-bottom: 4rem;
}

.product-bottom-flex {
    margin-bottom: 2rem;
}

.page-product-downloadable {
    .product-add-form {
        form {
            align-items: center;
        }
    }
}

.product-info-stock-sku {
    @include media-breakpoint-down($main-breakpoint) {
        display: none;
    }
}

.product-info-main {
    .price-box {
        font-size: $h2-font-size;
    }
}