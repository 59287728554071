.block-search {
    .actions {
        background-color: $secondary;
        
        .action.search {
            color: $white;
            border: $secondary;
            background: $secondary;
            @include svg-icon(1rem, 1rem, $icon-search, $white);
        }
    }

    #search {
        box-shadow: none;
        background-clip: border-box;
    }
}
.navbar-search {
    .icon {
        &::before {
            color: $white;
        }
    }
}
