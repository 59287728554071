@import "icons";

html {
    font-size: 14px;
}

/* fix for non working box shadow mixin */
.form-control {
    box-shadow: $input-box-shadow;
}

/* fix for non working box shadow mixin end */

.page-wrapper .breadcrumbs-wrapper {
    @include media-breakpoint-down($main-breakpoint) {
        min-height: 1rem;
    }
}

.breadcrumb {
    padding-top: 0;

    @include media-breakpoint-down($main-breakpoint) {
        padding-bottom: 0;
        padding-left: 0;
    }
}

.breadcrumb-item {
    a {
        color: $breadcrumb-active-color;
    }
}

.messages {
    .alert {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 1rem;
        color: $gray-700;
        border: 2px solid $primary;
        border-left-width: 6px;
        background-color: $white;

        &-error {
            border-left-color: $danger;
        }

        &.succes {
            color: $gray-700;
            background-color: $white;
            border-color: $primary;
            border-left-color: $green;
        }

        .close {
            position: static;
            color: $primary;
            font-size: 2rem;
            opacity: 1;

            &:hover {
                color: $primary;
            }
        }

        &-error>span {
            &::before {
                margin-right: $spacer;
                @include svg-icon(2rem, 2rem, $icon-circle-xmark, $danger);
                vertical-align: middle;
            }
        }
    }
}
