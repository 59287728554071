footer.footer {
    background: $primary;
    color: $white;
}

.footer-middle-wrapper {
    padding: map-get($spacers, 3) 0;

    .follow {
        a {
            &.btn-outline-primary {
                border-color: $white;

                &:hover {
                    background-color: $white;
                    transition: all 200ms ease-in-out;

                    .icon-twitter {
                        @include svg-icon(1.25rem, 1.25rem, $icon-twitter-x, $black);
                    }
                }

                .icon-twitter {
                    vertical-align: middle;
                    @include svg-icon(1.25rem, 1.25rem, $icon-twitter-x, $white);
                    transition: all 200ms ease-in-out;
                }
            }
        }
    }
}

.footer-block {
    color: $white;

    a {
        color: $white;
    }

    .block-title {
        color: $white;

        &.label-collapse {
            &:after {
                @include svg-icon(1.4rem, 1.4rem, $icon-minus-circle, $white);
            }

            &.collapsed {
                &:after {
                    @include svg-icon(1.4rem, 1.4rem, $icon-plus-circle, $white);
                }
            }
        }
    }

    .block-content {
        ul {
            &.check {
                li::before {
                    @include svg-icon(1rem, 1rem, $icon-check, $white);
                }
            }
        }
    }
}

.block-order-catalog {
    h3 {
        color: $white;
    }

    .btn-order-catalog {
        border-color: $secondary;

        &:hover {
            background-color: $black;
            color: $white;
        }
    }
}

.footer-bottom-wrapper {
    padding: 2.5rem 0;
    background-color: $secondary;

    .copyright {
        color: $white;
        font-size: 1rem;
        font-weight: 700;

        &::before {
            @include svg-icon(1rem, 1rem, $icon-copyright, $white);
            font-weight: 700;
        }
    }

    @include media-breakpoint-down(sm) {
        .copyright {
            margin-bottom: 1rem;
            text-align: center;
        }

        .block-payments {
            display: block;
            text-align: center;
        }
    }
}
