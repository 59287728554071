.checkout-index-index {
    header {
        &.page-header {
            background-color: $primary;
        }
    }

    fieldset {
        &.street {
            &>.control {
                display: block;
                
                .field {
                    max-width: none;
                    flex: none;
                    
                    &.additional .label {
                        display: none;
                    }
                }
            }
        }
    }

    .page-header {
        background-color: black;
        padding: map-get($spacers, 3);
    }

    .progress-authetication-wrapper {
        .opc-progress-bar {
            li._active span
            {
                border-color: $secondary;
            }
        }
    }
}

.checkout-onepage-success {
    header {
        background-color: $black;
    }
}