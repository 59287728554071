.block-newsletter {
    #newsletter {
        box-shadow: none;
        background-clip: border-box;
    }

    button.btn.primary {
        border-radius: 0;
        color: $white;
        background-color: $secondary;
        border-color: $secondary;
    }
}
