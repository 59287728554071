.home-cta {
    background-color: $gray-200;

    a {
        color: $black;
    }
}

.hero-block .hero-links-list .slide-item .hero-title {
    color: $secondary;
}

.hero-block .hero-links-list .slide-item.active {
    border-color: $secondary;
}
