.products-grid {
    .product-item {
        .product-item-actions {
            .more-info {
                color: $gray-800;
                font-weight: $font-weight-bold;

                &:hover {
                    color: $gray-800;
                }
            }
        }
    }
}